import { useEffect } from 'react';
import { Navigate } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react';
import { useTheme } from '@mui/material/styles';

import {
  Box,
  Button,
  SvgIcon,
  Typography
} from '@mui/material';

const Login = (props) => {
  const { oktaAuth, authState } = useOktaAuth();
  const theme = useTheme();

  const inStyle = theme.palette.title.white;
  const outStyle = theme.palette.title.main;

  useEffect(() => {
    console.log('login - user authenticated? ' + authState?.isAuthenticated);
  }, [authState])

  const handleLogin = async () => {
    window.sessionStorage.setItem('TriggerLogout', false);
    await oktaAuth.signInWithRedirect();
  }

  const layout = () => {
    if (authState?.isAuthenticated) {
      console.log('login -- Already authenticated. Going to main page.')
      return <Navigate to='/' replace={true} />
    } else {
      return (
        <Box className="login-layout"
          sx={{
            backgroundColor: theme.palette.primary.loginMain,
          }}
        >
          <Button className="login-button"
            sx={{
              backgroundColor: inStyle,
              color: outStyle,
              "&:hover": {
                backgroundColor: outStyle,
                color: inStyle
              }
            }}
            onClick={handleLogin}
          > 
            <SvgIcon className="login-icon"
              component={theme.Images.bigN}
              inheritViewBox
            />
            <Typography className="login-text">
                login
              </Typography>
          </Button>
        </Box>
      )
    }
  } 
  
  return layout();
}

export default Login;