
import { useState, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';

import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@mui/material';

const DropDown = (props) => {
  const theme = useTheme();
  const [value, setValue] = useState("");

  const handleChange = (evt) => {
    evt.preventDefault();
    setValue(evt.target.value);    
    props.setValue(evt.target.value);

  }

  useEffect(() => {    
    setValue("")
  },[props.reset])

  const label_props = props.label_props ? props.label_props : {}

  const layout = (
      <FormControl
        id={`fc-${props.id}`}
        size="small"
        disabled={props.disable}
        fullWidth={props.fullWidth ? props.fullWidth : false}
        color='dropdown'
        sx={{
          ...props.sx,
          maxHeight: props.height ? props.height : null,
          borderRadius: props.borderRadius ? props.borderRadius : 0
        }}
      >
        <InputLabel className="my-my-my-inputlabel"
          id={`il-${props.id}`}
          sx={{...label_props}}
        >
          {props.label}
        </InputLabel>
        <Select
          id={`sel-${props.id}`}
          sx={{
            maxHeight: props.height ? props.height : null,
            borderRadius: props.borderRadius ? props.borderRadius : 0            
          }}
          value={value}
          label={props.label}
          autoWidth={props.autoWidth}
          onChange={handleChange}
        >
          {props.inputs.map((_item, idx) => (
            <MenuItem key={idx} id={`mi-${idx}`} value={_item.value} sx={{display: "flex", flexDirection: "row", justifyContent: "space-between"}}>
              <Typography sx={{fontSize: 14}}>{_item.label}</Typography>
              {_item.icon}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  );

  return layout;
}

export default DropDown;