import { createContext, useState  } from "react";

export const ErrorContext = createContext(null);
export const ErrorContextProvider = (props) => {
  const [err, setErr] = useState(null);
  
  return (
    <ErrorContext.Provider value={{error: err, setError: setErr}}>
      {props.children}
    </ErrorContext.Provider>
  )
}

export const ShadowUserContext = createContext(null);
export const ShadowUserContextProvider = (props) => {
  const [shdUser, setShdUser] = useState(null);

  return (
    <ShadowUserContext.Provider
      value={{shdUser: shdUser, setShdUser: setShdUser}}
    >
      {props.children}
    </ShadowUserContext.Provider>
  )
}