import axios from 'axios';
import { createContext, useEffect} from 'react';
import { useOktaAuth } from "@okta/okta-react";

import { SGRoutes } from '../apps/Shotgun/contexts/SGBackendClientRoutes';

const RootAspects = {
  auth_user: {method: 'get', type: 'api', path: 'auth/user'},
  user_info: {method: 'get', type: 'api', path: 'user_info'},
  revoke: {method: 'post', type: 'api', path: 'auth/revoke'},
  auth_token: {method: 'post', type: 'api', path: 'auth/token'},
  sg_auth_user: {method: 'post', type: 'sg', path: 'query/user'},
  sg_event: {method: 'post', type: 'sg', path: 'evt_query'},
  sg_evt_history: {method: 'post', type: 'sg', path: 'evt_history_query'},
  sg_review_results: {method: 'post', type: 'sg', path: 'review_results'},
  sg_prescan_results: {method: 'post', type: 'sg', path: 'prescan_results'},
  sg_prescan_shipment: {method: 'post', type: 'sg', path: 'prescan_shipment'},
  sg_choose_tasks: {method: 'post', type: 'sg', path: 'choose_tasks'},
  sg_tiled_transcodes: {method: 'post', type: 'sg', path: 'tiled_transcodes'}
}

const Aspects = Object.assign({}, RootAspects, SGRoutes);

export const BackendAPIClientContext = createContext();

export const BackendAPIClientProvider = (props) => {
  const { authState, oktaAuth } = useOktaAuth();
  const client = axios.create({
      baseURL: window.location.origin,
      json: true
  })
  
  const perform = async (aspectName, payload={}, timeout=120000) => {
    const aspect = Aspects[aspectName];
    // console.log(aspect);
    // console.log(`request timeout (in seconds): ${timeout}`)
    if (aspect) {
      return client({
        method: aspect.method,
        url: `/${aspect.type}/${aspect.path}`,
        data: payload,
        timeout: timeout, // 10 second default
        headers: {
          Authorization: `Bearer ${authState.accessToken.accessToken}`
        }
      })
      .then(resp => {return resp.data ? resp.data : null;})
      .catch((err) => {
        console.log(err.message);
        console.log(err.name + " " + err.code)
        return JSON.stringify(
          {
            errmsg: `[Query error] ${err.message}`
          }
        );
      });
    } else {
      console.log(
        `Aspect key "${aspectName}" is not valid. Please check.`
      );
      return {};
    }
  }

  useEffect(() => {
    // console.log("Auth state updated");
  }, [authState])

  return (
    <BackendAPIClientContext.Provider
      value={{
        coreAPIConnect: perform,
      }}
    >
      {props.children}
    </BackendAPIClientContext.Provider>
    )
}