import { useEffect, useCallback, useContext, useState } from "react";
import { useTheme } from "@mui/material/styles";
import { Box, Typography, IconButton } from "@mui/material";

import DropDown from "../../../../common/components/DropDownNew";
import { BackendAPIClientContext } from "../../../../contexts/BackendAPIClientProvider";

const ShadowList = (props) => {
  const theme = useTheme();
  const BackendAPI = useContext(BackendAPIClientContext);

  const inputs = props.inputs;
  const [shadowActive, setShadowActive] = props.shadowActive;

  const [prevUser, setPrevUser] = useState(null);
  const [shdUser, setShdUser] = useState(null);
  
  const mappedInputs = inputs.map((itm) => {
    let Icon = <theme.Icons.userIcon />;
    if (itm.value.split(":")[1] == "Group") {
      Icon = <theme.Icons.groupIcon />;
    }
    return ({...itm, icon: Icon})
  })

  useEffect(() => {
    getShadowActive();
  }, [])

  useEffect(() => {
    if (shdUser !== null) {
      updateShadowAccess(false);
    }
  }, [shdUser]);

  useEffect(() => {
    const checkA = ([prevUser, shdUser].filter(n => n).length !== 0)
    const checkB = (prevUser !== shdUser)
    const megaCheck = (checkA && checkB)
    props.inner.current.trigger = megaCheck;
    props.inner.current.msg = prepShdUserMsg();
  }, [shadowActive])

  const prepShdUserMsg = () => {
    if (shdUser === null) {
      return ""
    } else {
      let usrname = shdUser.split(":")[2];
      return "Shadowing user - " + usrname;
    }
  }

  const getShadowActive = async() => {
    // console.log("ping")
    await BackendAPI.coreAPIConnect("sg_get_user_shadow_access", {})
    .then((data) => JSON.parse(data))
    .then((json) => {
      if (json.active) {
        setShadowActive(true)
        setShdUser(json.shadow_user);
        setPrevUser(json.shadow_user === null ? prevUser : json.shadow_user);
      }
    })
  }

  const updateShadowAccess = async (set_null) => {
    // console.log("pong")
    let _update = shdUser
    if (set_null) {
      _update = null
    }
    let packet = {
      update: _update
    }
    await BackendAPI.coreAPIConnect("sg_set_user_shadow_access", packet)
    .then((data) => JSON.parse(data))
    .then((json) => {
      if (set_null) {
        setShadowActive(false);
      } else {
        setShadowActive(true);
      }
    })
  }

  const clearShadow = () => {
    setShdUser(null);
    updateShadowAccess(true);
  };

  const mouseDownChange = (e) => {
    e.preventDefault();
  };

  const ShadowActive = () => {
    let shadowName = shdUser.split(":")[2];
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <Typography variant="msg-active-bold">
          Shadowing {shadowName}
        </Typography>
        <IconButton className="icon-button"
          aria-label="deactivate shadow"
          onClick={clearShadow}
          onMouseDown={mouseDownChange}
          edge="end"
          disableRipple
        >
          <theme.Icons.clearIcon />
        </IconButton>
      </Box>
    )
  }

  const ShadowDropDown = () => {
    return (
      <Box className="modal-user-dropdown-parent">
        <DropDown className="banner-dropdown-parent"
          id="user-shadow-select-dropdown"
          sx={{width: "100%"}}
          borderRadius={1}
          autoWidth
          height={30}
          label_props={{fontSize: 12, top: "-2px"}}
          label={"Shadow User Select"}
          inputs={mappedInputs}
          setValue={setShdUser}
        />
      </Box>
    )
  }

  return (
    <Box className="modal-user-shadow-select-parent">
      { shdUser !== null ? ShadowActive() : ShadowDropDown() }
    </Box>
  )
}

const User = (props) => {
  const theme = useTheme();
  const user = props.User;
  const configs = props.configs

  const [shadowActive, setShadowActive] = useState(false);

  const shadowLyt = () => {
    const can_show = (
      (user.auth_user.admin || user.sg_user.sg_atw_shadow_access) &&
      configs.length > 0
    )
    return (
      can_show ? 
      <ShadowList
        inner={props.inner}
        inputs={configs}
        shadowActive={[shadowActive, setShadowActive]}
      /> : null
    )
  }

  const layout = () => {
    const border = shadowActive ?
    `3px solid ${theme.palette.borders.active}` :
    `1px solid ${theme.palette.borders.inactive}`;
    return (
      <Box
        className="mui-box modal-user-parent"
        sx={{
          border: border,
          backgroundColor: theme.palette.primary.content,
        }}
      >
        <Typography>
          User: {user.auth_user.user_fullname} {user.auth_user.admin ? '[Admin]' : ''}
        </Typography>
        <Typography>Email: {user.auth_user.email}</Typography>
        <Typography>ATW ID: {user.auth_user.user_id}</Typography>
        <Typography>Shotgrid User ID: {user.auth_user.sg_user_id}</Typography>
        {shadowLyt()}
      </Box>
    )
  }

  return layout();
}

export default User;