import { useParams } from "react-router-dom";
import { useState } from "react";
import { withOktaAuth } from "@okta/okta-react";
import { useTheme } from "@mui/material/styles"
import { Box } from "@mui/material";

import ControlBar from "./components/ControlBar/ControlBar";
import LoadingIcon from "../../common/components/LoadingIcon";

const Home = (props) => {
  const {page} = useParams();
  // console.log(page)

  const theme = useTheme();

  // State initialization =====================================================
  const [loading, setLoading] = useState(true);
  const [viewSelected, setViewSelected] = useState(null);

  const layout = () => {
    return (
      <Box className="mui-box home-layout-parent"
        sx={{backgroundColor: theme.palette.primary.main}}
      >
        <Box className="home-ctrlbar-parent">
          <ControlBar
            auth={[props.authState, props.oktaAuth]}
            loading={[loading, setLoading]}
            viewSelection={[viewSelected, setViewSelected]}
          />
        </Box>
        <Box className="mui-box home-content-parent">
          {loading ? <LoadingIcon /> : viewSelected}
        </Box>
      </Box>
    );
  }
  return layout();
}

export default withOktaAuth(Home);