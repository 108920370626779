import { useTheme } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";

const About = (props) => {
  const theme = useTheme();
  const user = props.User;
  const layout = (
    <Box
      id='about-modal-container'
      sx={{
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 1,
        border: '1px solid black',
        backgroundColor: theme.palette.primary.content,
        p: 4,
      }}
    >
      <Typography>NAS AnimTech Web Dashboard build v{user.auth_user.system.build_ver}</Typography>
      <Typography>Last Updated: {user.auth_user.system.last_updated}</Typography>
    </Box>
  );

  return layout;
}

export default About;