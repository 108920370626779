// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js!./common.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js!./Main/Main.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../node_modules/css-loader/dist/cjs.js!./Shotgun/Shotgun.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: 'Lato', sans-serif; /* standard default font */
}

.prevent-select {
  -moz-user-select:none; /* firefox */
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  }

.no-scroll {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.no-scroll::-webkit-scrollbar {
  display: none;
}

.mui-box {
  display: flex;
  flex: 1;
}

.italic {
  font-style: italic;
}`, "",{"version":3,"sources":["webpack://./src/styles/styles.css"],"names":[],"mappings":"AAKA;EACE,+BAA+B,EAAE,0BAA0B;AAC7D;;AAEA;EACE,qBAAqB,EAAE,YAAY;EACnC,yBAAyB,EAAE,WAAW;EACtC,qBAAqB,EAAE,oBAAoB;EAC3C,iBAAiB,EAAE,oBAAoB;EACvC;;AAEF;EACE,wBAAwB;EACxB,qBAAqB;AACvB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;EACb,OAAO;AACT;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["@import \"./common.css\";\n@import \"./Main/Main.css\";\n@import \"./Shotgun/Shotgun.css\";\n\n\n* {\n  font-family: 'Lato', sans-serif; /* standard default font */\n}\n\n.prevent-select {\n  -moz-user-select:none; /* firefox */\n  -webkit-user-select: none; /* Safari */\n  -ms-user-select: none; /* IE 10 and IE 11 */\n  user-select: none; /* Standard syntax */\n  }\n\n.no-scroll {\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n}\n\n.no-scroll::-webkit-scrollbar {\n  display: none;\n}\n\n.mui-box {\n  display: flex;\n  flex: 1;\n}\n\n.italic {\n  font-style: italic;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
