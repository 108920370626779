import React, { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Outlet, Navigate, useFetcher } from "react-router-dom";
import { toRelativeUrl } from '@okta/okta-auth-js';

import LoadingIcon from '../../../common/components/LoadingIcon';

/*
  This is causing a double load of the authenticated route. I think
  it may have something to do with the state trigger - one thing happening
  and then happening again once state catches up. Need to address.
*/
export const RequireAuth = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [ notLoggedIn, setNotLoggedIn ] = useState(false);

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      setNotLoggedIn(true);
      let originalUri = toRelativeUrl(window.location.href, window.location.origin);
      oktaAuth.setOriginalUri(originalUri);

    } else {
      setNotLoggedIn(false)
    }
  }, [!!authState, authState?.isAuthenticated]);

  if (!authState || !authState?.isAuthenticated) {
    if (notLoggedIn) {
      console.log("logout trigger")
      // window.sessionStorage.setItem('TriggerLogout', true);
      return <Navigate to='/login' replace={true} />
    } else {
      return;
    }
  }
  return <Outlet />
}

// export const RequireAuth = () => {
//   const { oktaAuth, authState } = useOktaAuth();
//   const [ notLoggedIn, setNotLoggedIn ] = useState(false);
  
//   useEffect(() => {
//     if (!authState) {
//       console.log('authState not initialized.')
//       return;
//     }

//     if (!authState?.isAuthenticated) {
//       console.log('user not authenticated');
//       setNotLoggedIn(true);
//       let originalUri = toRelativeUrl(window.location.href, window.location.origin);
//       oktaAuth.setOriginalUri(originalUri);      

//     } else {
//       console.log('user is authenticated!')
//       setNotLoggedIn(false)
//     }
//   }, [oktaAuth, !!authState, authState?.isAuthenticated]);

//   if (!authState || !authState?.isAuthenticated) {
//     if (notLoggedIn) {
//       console.log("load login page");
//       window.sessionStorage.setItem('TriggerLogout', true);
//       return <Navigate to='/login' replace={true} />
//     } else {
//       console.log(
//         "Route not Authenticated. Waiting for authentication page to load."
//       );
//       return <LoadingIcon />
//     }
//   }

//   return (<Outlet />);
// }