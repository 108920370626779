// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
Specific style sheets can be used in concert with the theme provider.
however be aware that you can unintentionally override certain style
components by providing a class-name styling.
*/

/* ================================ COMMON ================================= */
.ami-tableCell-primary {
	font-weight: bold;
	text-align: center;
	border: 1px solid lightslategray;
}

.ami-tableCell-secondary {
	text-align: left;
	border: 1px solid lightgray;
	padding: 12px 22px;
	word-wrap: break-word;
}

.ami-table-compact {
	table-layout: fixed;
}

`, "",{"version":3,"sources":["webpack://./src/styles/Shotgun/SGRequests.css"],"names":[],"mappings":"AAAA;;;;CAIC;;AAED,8EAA8E;AAC9E;CACC,iBAAiB;CACjB,kBAAkB;CAClB,gCAAgC;AACjC;;AAEA;CACC,gBAAgB;CAChB,2BAA2B;CAC3B,kBAAkB;CAClB,qBAAqB;AACtB;;AAEA;CACC,mBAAmB;AACpB","sourcesContent":["/*\nSpecific style sheets can be used in concert with the theme provider.\nhowever be aware that you can unintentionally override certain style\ncomponents by providing a class-name styling.\n*/\n\n/* ================================ COMMON ================================= */\n.ami-tableCell-primary {\n\tfont-weight: bold;\n\ttext-align: center;\n\tborder: 1px solid lightslategray;\n}\n\n.ami-tableCell-secondary {\n\ttext-align: left;\n\tborder: 1px solid lightgray;\n\tpadding: 12px 22px;\n\tword-wrap: break-word;\n}\n\n.ami-table-compact {\n\ttable-layout: fixed;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
