// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.frame-slider {
  cursor: ew-resize;
  min-width: 5px;
  max-width: 5px;
  z-index: 100
}

.divider-horizontal-margin {
  margin-top: 10px;
  margin-bottom: 10px;
}

.divider-vertical-margin {
  margin-left: 10px;
  margin-right: 10px;
}

.divider-horizontal-thin {
  flex: 1;
  width: 100%;
  min-height: 1px;
  max-height: 1px;
}

.divider-vertical-thin {
  flex: 1;
  height: 100%;
  min-width: 1px;
  max-width: 1px;
}

.clearable-search-bar {
  flex: 1;
  border-radius: 8px;  
}

/* ================================= Modal ================================= */
.modal-viewer-parent-frame {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px,
  rgba(0, 0, 0, 0.14) 0px 24px 38px 3px,
  rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;
}

.modal-viewer-popup-image {
  max-height: 90vh;
  max-width: 90vw;
}

.modal-viewer-err-nomedia {
  padding: 48px;
}`, "",{"version":3,"sources":["webpack://./src/styles/common.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,cAAc;EACd,cAAc;EACd;AACF;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,OAAO;EACP,WAAW;EACX,eAAe;EACf,eAAe;AACjB;;AAEA;EACE,OAAO;EACP,YAAY;EACZ,cAAc;EACd,cAAc;AAChB;;AAEA;EACE,OAAO;EACP,kBAAkB;AACpB;;AAEA,8EAA8E;AAC9E;EACE,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,gCAAgC;EAChC,aAAa;EACb,uBAAuB;EACvB;;sCAEoC;AACtC;;AAEA;EACE,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,aAAa;AACf","sourcesContent":[".frame-slider {\n  cursor: ew-resize;\n  min-width: 5px;\n  max-width: 5px;\n  z-index: 100\n}\n\n.divider-horizontal-margin {\n  margin-top: 10px;\n  margin-bottom: 10px;\n}\n\n.divider-vertical-margin {\n  margin-left: 10px;\n  margin-right: 10px;\n}\n\n.divider-horizontal-thin {\n  flex: 1;\n  width: 100%;\n  min-height: 1px;\n  max-height: 1px;\n}\n\n.divider-vertical-thin {\n  flex: 1;\n  height: 100%;\n  min-width: 1px;\n  max-width: 1px;\n}\n\n.clearable-search-bar {\n  flex: 1;\n  border-radius: 8px;  \n}\n\n/* ================================= Modal ================================= */\n.modal-viewer-parent-frame {\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n  display: flex;\n  justify-content: center;\n  box-shadow: rgba(0, 0, 0, 0.2) 0px 11px 15px -7px,\n  rgba(0, 0, 0, 0.14) 0px 24px 38px 3px,\n  rgba(0, 0, 0, 0.12) 0px 9px 46px 8px;\n}\n\n.modal-viewer-popup-image {\n  max-height: 90vh;\n  max-width: 90vw;\n}\n\n.modal-viewer-err-nomedia {\n  padding: 48px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
