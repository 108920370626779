// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.login-layout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.login-button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 180px;
    height: 180px;
    border-radius: 50%;
    box-shadow: 0px 6px 6px 2px rgba(0, 0, 0, .3);
  }

.login-icon {
    font-size: 100px;
    color: inherit;    
}

.login-text {
    padding: 8px 0 0 0;
    font-size: 18px;
    color: inherit;
}`, "",{"version":3,"sources":["webpack://./src/styles/Main/Login.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,6CAA6C;EAC/C;;AAEF;IACI,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,cAAc;AAClB","sourcesContent":[".login-layout {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    height: 100vh;\n}\n\n.login-button {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    width: 180px;\n    height: 180px;\n    border-radius: 50%;\n    box-shadow: 0px 6px 6px 2px rgba(0, 0, 0, .3);\n  }\n\n.login-icon {\n    font-size: 100px;\n    color: inherit;    \n}\n\n.login-text {\n    padding: 8px 0 0 0;\n    font-size: 18px;\n    color: inherit;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
