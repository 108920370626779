export const checkErrMsg = (data) => {
  /*
  Provides a way to confirm if a backend result packet contains a 
  standardized state and error message.
  */
  const isValid = (val) => {
    return ![null, undefined].includes(val)
  }
  if (isValid(data.state)) {
    if (data.state === "success") {
      return null;
    } else {
      return data.data;
    }
  }
}